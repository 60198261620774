<template>
    <div class="main-block" v-loading="loading">
        <draggable-list
            :list-width="listWidth"
            :tasks="tasks"
            :lists="lists"
            :groups="groups"
            :list-header-template="listHeaderTemplate"
            :list-header-style="listHeaderStyle"
            :list-footer-template="listFooterTemplate"
            :list-footer-style="listFooterStyle"
            :task-template="taskTemplate"
            :task-style="taskStyle"
            @change="onChange"
        ></draggable-list>
      <el-dropdown trigger="click" class="options" @command="runOptionCommand" size="small">
      <span class="el-dropdown-link">
        <i class="el-icon-more trigger"></i>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-refresh-right" command="reload">{{ $locale.main.button.update }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <slot></slot>
    </div>
</template>

<script>
import mixin from '../mixins'
import DraggableList from './DraggableList'
import { statuses } from './DraggableList/data/data'
export default {
  name: 'a-tasks',
  components: {
    DraggableList
  },
  mixins: [mixin],
  inject: {
    isEditor: {
      default: () => false
    },
    openRegistryCard: {
      default: () => {}
    }
  },
  provide () {
    return { loadData: this.loadData }
  },
  props: {
    listWidth: {
      type: Number,
      default: 300,
      description: 'Ширина колонки'
    },
    source: {
      type: Object,
      default () {
        return {
          type: null,
          group_list_field: null,
          entityId: null,
          additionalFields: []
        }
      },
      editor: 'TasksSource'
    },
    limit: {
      type: Number,
      default: 100,
      description: 'Лимит записей'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: false,
        showEqualsTypes: true
      }
    },
    defaultOrder: {
      type: String,
      default: null,
      description: 'Сортировка'
    },
    groupFields: {
      type: Array,
      default () {
        return []
      },
      editor: 'TasksGroups'
    },
    listHeaderStyle: {
      type: String,
      description: 'Стиль хэдера',
      default: 'background: #E9EBED;border-radius: 8px;'
    },
    listFooterStyle: {
      type: String,
      description: 'Стиль футера',
      default: 'background: #E9EBED;border-radius: 8px;'
    },
    taskStyle: {
      type: String,
      description: 'Стиль блока',
      default: 'background: #FFFFFF;border-radius: 8px;min-height: 200px;cursor: pointer;margin: 6px;'
    },
    changeInteractive: {
      type: Object,
      default: () => {
        return {
          type: null,
          properties: {}
        }
      },
      editor: 'TasksChangeInteractive'
    },
    listHeaderTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 48
      },
      description: 'Шаблон хэдера',
      editor: 'HtmlDesign'
    },
    listFooterTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 48
      },
      description: 'Шаблон футера',
      editor: 'HtmlDesign'
    },
    taskTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 200
      },
      description: 'Шаблон блока',
      editor: 'HtmlDesign'
    }
  },
  data () {
    return {
      tasks: [],
      lists: [],
      groups: [],
      loading: false
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    dataFilters: {
      immediate: false,
      handler () {
        this.loadData()
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = item.equalsType || 'eq'
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              if (type === 'eqx' && parseInt(this.getModel()[item.attribute]) === 0) {
                return
              }
              filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          } else if (item.type === 'current_user' && item.alias) {
            filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    }
  },
  methods: {
    runOptionCommand (command) {
      switch (command) {
        case 'reload':
          this.loadData()
          break
        default:
          break
      }
    },
    onChange (element, list) {
      if (this.changeInteractive.type === 'change_field' && this.source.type === 'registry') {
        let groupFieldValue = list.name
        let groupField = this.source.group_list_field
        try {
          groupFieldValue = JSON.parse(groupFieldValue)
          groupFieldValue = groupFieldValue[0].id
        } catch (e) {
        }

        let data = {
          object_id: this.source.entityId,
          id: element.element.id
        }
        data[groupField] = groupFieldValue
        this.$http.put(`${this.$config.api}/registryservice/registry/${this.source.entityId}/records/${element.element.id}`, data)

        console.log(element)
      }
    },
    async loadData () {
      if (!this.source.type || !this.source.entityId || !this.source.group_list_field) {
        return false
      }
      this.loading = true
      switch (this.source.type) {
        case 'registry':
          let tasks = await this.getRegistryData(this.source.entityId)
          this.tasks = tasks.map((item) => Object.assign(item, { list: item[this.source.group_list_field] })).sort((a, b) => {
            let comparison = 0
            if (!this.defaultOrder) {
              return comparison
            }
            const fieldA = (a[this.defaultOrder] || '').toUpperCase()
            const fieldB = (b[this.defaultOrder] || '').toUpperCase()

            if (fieldA > fieldB) {
              comparison = 1
            } else if (fieldA < fieldB) {
              comparison = -1
            }

            return comparison
          })
          this.lists = this.getLists(this.tasks, this.source.group_list_field, this.source.additionalFields)
          this.groups = this.getGroupedTasks(this.tasks, this.groupFields)
      }
      this.loading = false
    },
    async getRegistryData (entityId) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${entityId}/data?limit=${this.limit}&${this.dataFilters.map((filter, index) => `filter[${index}]=${filter}`).join('&')}`)
      return data.data.data
    },
    getLists (tasks, group, additionalFields) {
      let lists = []
      let count = {}

      let sums = {}

      additionalFields.forEach((item) => {
        if (item.type === 'sum') {
          sums[item.attribute] = {}
        }
      })

      tasks.forEach((item) => {
        if (typeof count[item[group]] === 'undefined') {
          lists.push({ name: item[group] })
        }
        count[item[group]] = (count[item[group]] || 0) + 1

        for (let key in sums) {
          if (!sums.hasOwnProperty(key)) {
            continue
          }
          if (typeof sums[key][item[group]] === 'undefined') {
            sums[key][item[group]] = 0
          }
          sums[key][item[group]] = sums[key][item[group]] + (parseFloat(item[key]) || 0)
        }
      })

      return lists.map((item) => {
        let object = {
          name: item.name,
          count: count[item.name] || 0
        }

        for (let key in sums) {
          if (!sums.hasOwnProperty(key)) {
            continue
          }
          object[`${key}sum`] = (sums[key][item.name] || 0).toFixed(2)
        }

        return object
      })
    },
    getGroupedTasks (tasks, groups) {
      if ((groups || []).length === 0) {
        return []
      }
      let result = this.getGroup(tasks, groups[0].name)
      if (groups.length > 1) {
        result = result.map((item) => {
          return {
            name: item.name,
            children: this.getGroup(item.tasks, groups[1].name)
          }
        })
      }

      return result
    },
    getGroup (tasks, group) {
      let groups = this.groupBy(tasks, group)
      let result = []
      for (let name in groups) {
        result.push({
          name: name,
          tasks: groups[name]
        })
      }

      return result
    },
    groupBy (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    }
  }
}
</script>

<style scoped>
.main-block {
    height: 100%;
    display: contents;
}
.options {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  z-index: 99999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) -1px 1px 2px;
  border-bottom-left-radius: 5px;
}
.options .trigger {
  cursor: pointer;
  color: #409EFF;
}
</style>
